/** @jsxRuntime classic */
/** @jsx jsx */
import BrandBanner from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandBanner';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import EmbedLayout from '../../../../components/EmbedLayout';
import {
  ArrowRightIcon,
  Flex,
  Link,
  Text,
} from '@bottlebooks/gatsby-theme-base';
import { darken } from 'polished';

export default function BrellaExhibitorPage({ data }) {
  const { exhibitor } = afterQuery(data);
  return (
    <EmbedLayout>
      <Link
        href={`https://globalbuyersmarket.californiawines.bottlebooks.me/exhibitors/${exhibitor.exhibitorId}`}
        target="_blank"
      >
        <Flex
          justify="center"
          align="center"
          gap={2}
          sx={{
            paddingY: 2,
            paddingX: 3,
            backgroundColor: '#258e2a',
            color: 'white',
            ':hover': {
              backgroundColor: darken(0.1)('#258e2a'),
              color: '#efefef',
            },
          }}
        >
          <Text>View complete winery details on the event website.</Text>
          <ArrowRightIcon size="smallMedium" />
        </Flex>
      </Link>
      <BrandBanner {...exhibitor} sx={{ height: 500 }} />
    </EmbedLayout>
  );
}

export const pageQuery = graphql`
  query BrellaExhibitorPage($partnerId: ID!) {
    exhibitor(exhibitorId: { eq: $partnerId }) {
      exhibitorId
      mainImage {
        fluid(maxWidth: 1770, maxHeight: 1000, crop: PAD) {
          src
          srcSet
          sizes
          base64
          aspectRatio
        }
      }
    }
  }
`;

function afterQuery(data) {
  return { exhibitor: data?.exhibitor };
}
